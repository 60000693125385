import { CreditCardOutlined } from '@ant-design/icons';
import { Button, Card, Form, InputNumber, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import apiconfig from '../apiconfig';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { HTTP_303_SEE_OTHER } from '../utils/http_codes';
import { convertToLocalDateTime } from '../utils/utils';

const { Title, Paragraph } = Typography;
const { Item } = Form;

const SubscriptionPage = () => {
    const { authTokens, userProfile, setUserData } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [form] = Form.useForm();
    const [nbOfJobsState, setNbOfJobsState] = useState(1);
    const [subscriptionButtonLoading, setSubscriptionButtonLoading] = useState(false);
    const [manageSubButtonLoading, setManageSubButtonLoading] = useState(false);
    const [hasRefreshed, setHasRefreshed] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const fetchUserData = async () => {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authTokens.access}`
                }
            };
            try {
                const user_data = await axiosPrivate.get(apiconfig.USER_DATA, config);
                setUserData(user_data.data);
                setHasRefreshed(true);
            } catch (err) {
                console.error("User data not avaiable.", err);
            }
        };

        const queryParams = new URLSearchParams(location.search);
        
        if (queryParams.get('user_data') === 'refresh' && !hasRefreshed) {
            fetchUserData();
        }
    }, [location, axiosPrivate, authTokens.access, setUserData, hasRefreshed]);


    const onClickSubscribe = async (values) => {
        setSubscriptionButtonLoading(true)
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            }
        };
        const data = JSON.stringify({ "quantity": values.quantity });
        try {
            await axiosPrivate.post(apiconfig.STRIPE_CREATE_CHECKOUT_SESSION, data, config);
        } catch (err) {
            if (err.response.status === HTTP_303_SEE_OTHER) {
                setSubscriptionButtonLoading(false)
                const redirect_url = err.response.data;
                window.location.replace(redirect_url);
            }
        }
        setSubscriptionButtonLoading(false)
    }
    const onClickCustomersPortal = async (values) => {
        setManageSubButtonLoading(true)
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            }
        };
        try {
            await axiosPrivate.get(apiconfig.STRIPE_CREATE_CUSTOMER_PORTAL_SESSION, config);
        } catch (err) {
            console.error(err.response);
            if (err.response.status === HTTP_303_SEE_OTHER) {
                setManageSubButtonLoading(false)
                const redirect_url = err.response.data;
                window.location.replace(redirect_url);
            }
        }
        setManageSubButtonLoading(false)
    }
    return (
        <>
            <Row justify='center'>
                <Title level={2}><CreditCardOutlined /> Subscription</Title>
            </Row>
            <Card style={{ minWidth: '200px', minHeight: '200px' }}>
                {/* subscriptionButtonLoading */}
                {/* userProfile?.user_profile?.is_subscriber */}
                {userProfile?.user_profile?.is_subscriber ?
                    <>
                        <Row justify='center'>
                            <Card title={"You subscription"} style={{ minWidth: '350px' }}>
                                <Title level={2}> $ {userProfile?.user_profile?.max_jobs_number * 2} / month</Title>
                                <ul>
                                    <li>Maximum number of jobs: <b>{userProfile?.user_profile?.max_jobs_number}</b></li>
                                    <li>Next payment: <b>{convertToLocalDateTime(userProfile?.user_profile?.subscription_end)}</b></li>
                                </ul>
                                <Button
                                    type="primary"
                                    onClick={onClickCustomersPortal}
                                    loading={manageSubButtonLoading}
                                >Manage Subscription</Button>
                            </Card>
                        </Row>
                    </>
                    :
                    <Row justify='center'>
                        <Card style={{ width: '350px', }}>
                            <Row justify="center">
                                <Title level={3}>Monthly subscription</Title>
                            </Row>

                            <Row justify="center">
                                <Paragraph>$ 2 / Job / Month</Paragraph>
                            </Row>

                            <Form
                                form={form}
                                initialValues={{ quantity: 1 }}
                                layout="vertical"
                                onFinish={onClickSubscribe}
                                onFinishFailed={null}
                            >
                                <Row justify="center">
                                    <Item label="Choose the quantity of jobs to subscribe to" name="quantity" rules={[{ required: true, message: 'Please choose the quantity!' }]} >
                                        <InputNumber
                                            style={{ width: '100%', minWidth: '100px' }}
                                            min={1}
                                            max={500}
                                            placeholder="Number of jobs"
                                            onChange={(nb) => { setNbOfJobsState(nb) }}
                                        />
                                    </Item>
                                </Row>
                                <Row justify="center">
                                    <Title style={{ margin: '30px', padding: 0 }}>$ {2 * nbOfJobsState} / month</Title>
                                </Row>
                                <Item>
                                    <Button
                                        icon={null}
                                        block
                                        size="large"
                                        type="primary"
                                        htmlType="submit"
                                        loading={subscriptionButtonLoading}
                                    >Start subscription now!</Button>
                                </Item>
                            </Form>
                        </Card>
                    </Row>
                }
            </Card>
        </>
    )
}

export default SubscriptionPage;


