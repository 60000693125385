import { CaretRightOutlined, DeleteOutlined, PauseOutlined, PlusOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, List, Modal, Row, Select, Space, Table, Typography, message } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiconfig from '../apiconfig';
import JobModal from '../components/JobModal';
import JobResultModal from '../components/JobResultModal';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { HTTP_200_OK, HTTP_204_NO_CONTENT } from '../utils/http_codes';
import PrepareTableData from './logic/table_data';
const { Title } = Typography;

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    width: '80px',
  },
  {
    title: 'Job name',
    dataIndex: 'user_job_name',
    key: 'user_job_name',
    width: '300px',
  },
  {
    title: 'Time pattern',
    dataIndex: 'cron_or_interval',
    key: 'cron_or_interval',
    width: '150px',
  },
  {
    title: 'Method',
    dataIndex: 'http_method',
    key: 'http_method',
    width: '60px',
  },
  {
    title: 'ULR',
    dataIndex: 'http_url',
    key: 'http_url',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    width: '80px',
  },
  {
    title: 'Logs',
    dataIndex: 'logs',
    key: 'logs',
    width: '80px',
  },
  {
    title: 'Enabled',
    dataIndex: 'enabled',
    key: 'enabled',
    width: '60px',
  },
  {
    title: 'Runs',
    dataIndex: 'total_run_count',
    key: 'total_run_count',
    width: '80px',
  },
];

const JobsPage = () => {
  const { authTokens, userProfile } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  /* USER DATA */
  // userProfile.user_profile.is_subscriber
  // console.log(userProfile.user_profile.is_subscriber)

  /* MODAL */
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  /* TABLE */
  const [tableItemsData, setTableItemsData] = useState([]); // TABLE DATA
  const [selectedTableRowKeys, setSelectedTableRowKeys] = useState([]);
  const [selectedTableData, setSelectedTableData] = useState([]);
  const [anythingSelected, setAnythingSelected] = useState(false);
  const [reloadTable, setReloadTable] = useState([false]);
  const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
  const tableReaload = () => setReloadTable(!reloadTable);

  /* BULK ACTIONS SELECT */
  const [selectedAction, setSelectedAction] = useState(null);

  /* JOB MODAL */
  const [isJobModalOpen, setJobModal] = useState(false);
  const [jobModalMode, setJobModalMode] = useState('create');
  const [jobModalData, setJobModalData] = useState(null);

  /* JOB RESULTS MODAL */
  const [isJobResultsModalOpen, setJobResultsModal] = useState(false);
  const [jobResultsModalData, setJobResultsModalData] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();


  const getJobResults = async (obj) => {
    let output_obj = {}
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authTokens.access}`
      }
    };
    try {
      const response = await axiosPrivate.get(apiconfig.API_JOBS_RESULTS + obj.id.toString(), config);
      if (response.status === HTTP_200_OK) {
        output_obj = { ...obj, job_results: response.data }
      }
    }
    catch (err) {
      console.error("Can't fetch the job results data!")
    }

    try {
      const response_2 = await axiosPrivate.get(apiconfig.API_JOBS_FAIL_SUCCESS_COUNTER + obj.id.toString(), config);
      if (response_2.status === HTTP_200_OK) {
        setJobResultsModalData({ ...output_obj, job_fail_success_counter: response_2.data })
      }
    }
    catch (err) {
      console.error("Can't fetch the job stats data!")
    }
  }

  /* EDIT BUTTON INSIDE THE TABLE */
  const editClick = async (job_id) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authTokens.access}`
      }
    };
    try {
      const response = await axiosPrivate.get(apiconfig.API_JOBS + job_id.toString(), config);
      if (response.status === HTTP_200_OK) {
        setJobModalData(response.data)
      }
    }
    catch (err) {
      console.error("Can't fetch the job data!")
    }
    setJobModalMode('update')
    setJobModal(true) // Change the name
  }
  /* ENABLE/DISABLE BUTTON INSIDE THE TABLE */
  const enabledClick = async (id, enabled) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authTokens.access}`
      }
    };
    try {
      const response = await axiosPrivate.patch(
        apiconfig.API_JOBS + id.toString(),
        JSON.stringify({ "enabled": enabled }),
        config
      );
      if (response.status === HTTP_204_NO_CONTENT) { }
    } catch (err) {
      console.error(`Job with id=${id} was not ${enabled ? 'enabled' : 'disabled'}!`);
    }
    deselectAndReloadTable();
  }

  /* DELETE BUTTON INSIDE THE TABLE */
  const deleteClick = async (id) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authTokens.access}`
      }
    };

    try {
      const response = await axiosPrivate.delete(apiconfig.API_JOBS + id.toString(), config);
      if (response.status === HTTP_204_NO_CONTENT) { }
    } catch (err) {
      console.error(`Job with id=${id} was not removed!`);
    }
    deselectAndReloadTable();
  }

  /* LOGS BUTTON INSIDE THE TABLE */
  const logsClick = (obj) => {
    setJobResultsModalData(null)
    getJobResults(obj)
    setJobResultsModal(true)
  }

  useEffect(() => {
    setTableLoadingStatus(true)
    let fetchData = async () => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`
        }
      };
      try {
        const response = await axiosPrivate.get(apiconfig.API_JOBS, config);
        if (response?.status === HTTP_200_OK) {
          const table_data = PrepareTableData(response.data, enabledClick, editClick, deleteClick, logsClick);
          setTableItemsData(table_data)
          setTableLoadingStatus(false)
        }
      }
      catch (err) {
        console.error(err)
      }
    }
    fetchData();
  }, [reloadTable])

  const onSelectChange = (newSelectedRowKeys) => {
    setAnythingSelected(newSelectedRowKeys.length > 0) // When <Select> is not diabled
    setSelectedTableRowKeys(newSelectedRowKeys)
    setSelectedTableData(tableItemsData.filter(item => newSelectedRowKeys.includes(item.id)))
  }

  const rowSelection = {
    selectedRowKeys: selectedTableRowKeys,
    onChange: onSelectChange,
  };

  const showModal = (value) => {
    setSelectedAction(value)
    switch (value) {
      case 'enable':
        setModalMessage('Do you want to ENABLE following jobs?')
        break
      case 'disable':
        setModalMessage('Do you want to DISABLE following jobs?')
        break
      case 'delete':
        setModalMessage('Do you want to DELETE following jobs?')
    }
    // RUN AFTER SELECTION
    setIsModalOpen(true);
  };

  const deselectAndReloadTable = () => {
    setSelectedTableRowKeys([])
    setSelectedTableData([])
    setAnythingSelected(false) // <Select>
    setReloadTable(!reloadTable)
  }

  const handleModalOk = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authTokens.access}`
      }
    };
    const selected_jobs_id = selectedTableData.map(obj => obj.id);
    if (selected_jobs_id.length === 0) {
      // Nothing is selected
      setIsModalOpen(false);
      return
    }
    switch (selectedAction) {
      case 'enable':
        for (let i = 0; i < selected_jobs_id.length; i++) {
          try {
            const response = await axiosPrivate.patch(
              apiconfig.API_JOBS + selected_jobs_id[i].toString(),
              JSON.stringify({ "enabled": true }),
              config
            );
            if (response.status === HTTP_204_NO_CONTENT) { }
          } catch (err) {
            console.error(`Job with id=${selected_jobs_id[i]} was not enabled!`);
          }
        }
        deselectAndReloadTable();
        break;
      case 'disable':
        for (let i = 0; i < selected_jobs_id.length; i++) {
          try {
            const response = await axiosPrivate.patch(
              apiconfig.API_JOBS + selected_jobs_id[i].toString(),
              JSON.stringify({ "enabled": false }),
              config
            );
            if (response.status === HTTP_204_NO_CONTENT) { }
          } catch (err) {
            console.error(`Job with id=${selected_jobs_id[i]} was not disabled!`);
          }
        }
        deselectAndReloadTable();
        break;
      case 'delete':
        for (let i = 0; i < selected_jobs_id.length; i++) {
          try {
            const response = await axiosPrivate.delete(apiconfig.API_JOBS + selected_jobs_id[i].toString(), config);
            if (response.status === HTTP_204_NO_CONTENT) { }
          } catch (err) {
            console.error(`Job with id=${selected_jobs_id[i]} was not removed!`);
          }
        }
        deselectAndReloadTable();
        break;
    }
    setSelectedAction(null) // <Select>
    setIsModalOpen(false);
  };

  const handleModalCancel = () => {
    // DO NOTHING
    setIsModalOpen(false);
  };

  /* ---------------------------------------- */
  // UPDATE
  /* ---------------------------------------- */

  return (
    <>
      {contextHolder}
      <Row justify='center'>
        <Title level={2}><ThunderboltOutlined /> Jobs</Title>
      </Row>
      <Card>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          {
            !userProfile.user_profile.is_subscriber ? 
            <Row justify='center'>
              <Alert
                message={<>Please <b><Link to='/subscription'>Subscribe</Link></b> to Cronbe.</>}
                type="warning"
                description="To create and manage Jobs, you will need to have an active subscription."
                showIcon
              />
            </Row>
            :
            null
          }

          <Row justify='space-between'>
            <Col>
              <Space>
                <Button onClick={() => { setJobModalMode('create'); setJobModal(true); }}
                  type="primary"
                  size="large"
                  style={{ fontWeight: 'bold' }}
                  disabled={!userProfile.user_profile.is_subscriber}
                  >
                  <PlusOutlined />New job
                </Button>
                <Select
                  disabled={!anythingSelected}
                  value={null}
                  size="large"
                  style={{ width: '200px' }}
                  placeholder='Bulk actions'
                  options={[
                    { value: 'enable', label: <><CaretRightOutlined />{"   "}Enable</> },
                    { value: 'disable', label: <><PauseOutlined />{"   "}Disable</> },
                    { value: 'delete', label: <><DeleteOutlined />{"   "}Delete</> }]}
                  onSelect={showModal}
                >
                </Select>
              </Space>
            </Col>
            <Col flex="auto"></Col>
          </Row>
          <Table
            rowKey='id'
            // bordered
            size='small'
            rowSelection={rowSelection}
            columns={columns}
            dataSource={tableItemsData}
            scroll={{ x: 240, }}
            // showHeader={false}
            loading={tableLoadingStatus}
          />
        </Space>
      </Card>

      <JobModal
        title={jobModalMode === 'create' ? "Create new job" : "Update job"}
        open={isJobModalOpen}
        setOpenClose={setJobModal}
        mode={jobModalMode}
        update_data={jobModalData}
        tableReaload={tableReaload}
        messageApi={messageApi}
        contextHolder={contextHolder}
      />

      <JobResultModal
        title={"Job '" + (jobResultsModalData?.id)?.toString() + "' results"}
        open={isJobResultsModalOpen}
        data={jobResultsModalData}
        setOpenClose={setJobResultsModal}
      />

      <Modal
        title={modalMessage}
        open={isModalOpen}
        onOk={handleModalOk}
        onCancel={handleModalCancel}>
        <List
          size="small"
          bordered
          dataSource={selectedTableData}
          renderItem={(item) => <List.Item>{item.user_job_name}</List.Item>}
        />
      </Modal>
    </>
  )
}
export default JobsPage;