// REACT
import React, { useEffect } from 'react';

// CSS
import './App.css';
import { trackPageView } from "./analytics";

// REACT ROUTER DOM
import { Route, Routes, useLocation } from "react-router-dom";

/* PROJECT */
import RequireAuth from './components/RequireAuth';
import AccountSettingsPage from './pages/AccountSettingsPage';
import ErrorPage404 from './pages/ErrorPage404';
import JobsPage from './pages/JobsPage';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import RequestPassswordReset from './pages/RequestPasswordReset';
import ResetPasswordConfirm from './pages/ResetPasswordConfirm';
import SubscriptionPage from './pages/SubscriptionPage';
import UserActivation from './pages/UserActivation';
import PrivacyPolicy from './public_pages/legal/PrivacyPolicy';
import TermsOfService from './public_pages/legal/TermsOfService';
import ProtectedLayout from './utils/ProtectedLayout';

const App = () => {
  return (
    <>
      <AnalyticsTracker />
      <Routes>
        {/* PUBLIC ROUTES */}
        <Route index element={<LandingPage />} />
        <Route path="/" element={<LandingPage />} />

        {/* USERS */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/activate/:uid/:token" element={<UserActivation />} />
        <Route path="/reset_password" element={<RequestPassswordReset />} />
        <Route path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} />

        {/* LEGAL */}
        <Route path="/legal/terms-of-service" element={<TermsOfService />} />
        <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />

        {/* PROTECTED ROUTES */}
        <Route element={<RequireAuth />}>
          <Route path="/" element={<ProtectedLayout />}>
            {/* <Route path="/dashboard" element={<DashboardPage />} /> */}
            <Route path="/jobs" element={<JobsPage />} />
            <Route path="/settings" element={<AccountSettingsPage />} />
            <Route path="/subscription" element={<SubscriptionPage />} />
            <Route path="/logout" element={<SubscriptionPage />} />
          </Route>
        </Route>
        {/* CATCH ALL */}
        <Route path="*" element={<ErrorPage404 />} />
      </Routes>
    </>
  );
};

// Component to track page views
const AnalyticsTracker = () => {
  const location = useLocation();
  useEffect(() => {
    // Track page view on location change
    trackPageView(location.pathname + location.search);
  }, [location]);

  return null; // This component only tracks page views, so it renders nothing
};

export default App;

